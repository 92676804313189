import type { FC } from 'react';
import { Typography, Tooltip } from '@mui/material';
import type { Theme, SxProps } from '@mui/material';
import { format } from 'date-fns';

interface Props {
  sx?: SxProps<Theme>;
  formatValue?: string;
  value: string;
  formatToolTip?: string;
}

export const TextDate: FC<Props> = ({
  formatToolTip = 'PPpp',
  formatValue = 'yyyy/MM/dd',
  sx,
  value,
}) => {
  if (!!value)
    return (
      <Tooltip title={format(new Date(value || new Date()), formatToolTip)}>
        <Typography sx={sx}>{format(new Date(value || new Date()), formatValue)}</Typography>
      </Tooltip>
    );
  return <></>;
};
