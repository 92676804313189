/* eslint-disable @typescript-eslint/indent */
import type { FC } from 'react';
import { useState } from 'react';
import Table from 'components/data-table/Table';
import { useNavigate } from 'react-router-dom';

import { getAllQuery } from 'helpers/query-string-url';
import UserTypeLabel from 'components/UserTypeLabel';
import { TextDate } from 'components/Date';

export const EmailEnteredUser: FC = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState<any>({
    email: '',
    unique_filter: 'unique-email',
    type: 'all',
    ...getAllQuery(),
  });

  return (
    <>
      <Table
        FiltersValues={values}
        keyData={`data.data.users`}
        url={`dashboard/all-user/temp-email-users`}
        QueryKey={''}
        BackendFilters={[
          {
            name: 'email',
            type: 'text',
            onChange: setValues,
            values: values,
            placeholder: 'Search Email………………',
          },
          {
            name: 'unique_filter',
            type: 'select',
            label: 'Email unique',
            onChange: setValues,
            values: values,
            placeholder: 'Email unique',
            option: [
              {
                label: 'All',
                value: 'all',
              },
              {
                label: 'Unique Email',
                value: 'unique-email',
              },
            ],
          },
          {
            name: 'type',
            type: 'select',
            label: 'Type',
            onChange: setValues,
            values: values,
            placeholder: 'Type',
            option: [
              {
                label: 'All',
                value: 'all',
              },
              {
                label: 'Get married',
                value: 'get_married',
              },
              {
                label: 'Wali',
                value: 'wali',
              },
            ],
          },
          {
            label: 'Start Date',
            placeholder: 'Start Day ...',
            name: 'start_date',
            type: 'day-picker',
            onChange: setValues,
            values: values,
            sx: {
              width: {
                xs: '100%',
              },
            },
          },
          {
            label: 'End Date',
            placeholder: 'End Day ...',
            name: 'end_date',
            type: 'day-picker',
            onChange: setValues,
            values: values,
            sx: {
              width: {
                xs: '100%',
              },
            },
          },
        ]}
        filterComeFromBackend={true}
        columns={[
          {
            Header: '#User Id',
            accessor: 'id',
          },
          {
            Header: 'User Type',
            accessor: 'type',
            Cell: (props) => {
              return <UserTypeLabel type={props.value} gender={props.row.original.gender} />;
            },
          },
          {
            Header: 'Email',
            accessor: 'email',
          },
          {
            Header: 'Register Type',
            accessor: 'register_type',
          },
          {
            Header: 'Joined',
            accessor: 'created_date',
            Cell: (props) => <TextDate value={props.value} />,
          },
          {
            Header: 'Email Verified',
            accessor: 'is_email_verified',
            Cell: (props) => (props.value ? <p>Verified</p> : <p>Not verified</p>),
          },
          {
            Header: 'Device id',
            accessor: 'device_id',
          },
          {
            Header: 'Device Type',
            accessor: 'device_type',
          },
          {
            Header: 'Email Enabled',
            Cell: (props) =>
              props.row.original.updates_emails_enabled &&
              props.row.original.marketing_emails_enabled ? (
                <p>Updates & Marketing</p>
              ) : props.row.original.updates_emails_enabled ? (
                <p>Updates</p>
              ) : props.row.original.marketing_emails_enabled ? (
                <p>Marketing</p>
              ) : (
                <p>Not enabled</p>
              ),
          },
        ]}
        actionFunctions={{
          viewAction: (u: any) => {
            let url = 'users';
            if (u?.type == 'wali') {
              url = 'walis';
            }
            navigate(`/dashboard/${url}/${u?.id}`, { replace: true });
          },
        }}
      />
    </>
  );
};
